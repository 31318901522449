<template>
  <div class="website">
    <div class="banner1">
      <img src="../../images/website-banner1.webp" />
      <img class="btn" src="../../images/moreCaseBtn.png" @click="onClickBtn" />
    </div>
    <div class="banner2">
      <img src="../../images/website-banner2.webp" />
    </div>
    <section class="caseShow">
      <div class="header">
        <h2 class="title">案列展示</h2>
      </div>
      <div class="box">
        <m-case-box :navId="navId" />
      </div>
    </section>
    <investment></investment>
  </div>
</template>

<script>
import mCaseBox from '@/components/mCaseBox'
import investment from '@/components/investment'
export default {
  components: {
    mCaseBox,
    investment,
  },
  created() {
    this.navId = this.$route.query.navId
  },
  methods: {
    onClickBtn() {
      this.$router.push({ name: 'case', query: { navId: '' } })
    },
  },
}
</script>

<style lang="scss">
.website {
  .banner1,
  .banner2 {
    width: 100%;
  }
  .banner1 {
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
    .btn {
      cursor: pointer;
      position: absolute;
      width: 14%;
      bottom: 15%;
      right: 17%;
    }
  }
  .caseShow {
    padding: 0 0 58px;
    background-color: #f4f5f9;
    .header {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: #fff;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 2px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
    }
    .box {
      padding-top: 36px;
    }
  }
}
</style>
